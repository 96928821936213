import React from 'react';

const ChevronUp = ({ className }: { className?: string }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 22.493 14.527">
			<path
				d="m12.435.496 9.563 9.562a1.681 1.681 0 0 1 0 2.384l-1.589 1.589a1.681 1.681 0 0 1-2.384 0l-6.778-6.778-6.778 6.778a1.681 1.681 0 0 1-2.384 0L.496 12.442a1.681 1.681 0 0 1 0-2.384L10.058.496a1.672 1.672 0 0 1 2.377 0Z"
				fill="#0277bd"
			/>
		</svg>
	);
};

export default ChevronUp;
