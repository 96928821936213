import React from 'react';

import PageSection from '@components/Layout/PageSection/PageSection';
import ProductListing from '@components/Listing/ProductListing';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import ProductSearch from '@components/Search/ProductSearch';
import H from '@components/Typography/H';

const ProductsPage = () => {
	return (
		<Page>
			<SEO
				title="Products | UHV Design"
				description="As a market leader in UHV motion and heating solutions since 1993, we have a wide variety of available products to choose from. View range."
			/>

			<PageSection>
				<H level={2} as="h1">
					Products
				</H>

				<ProductSearch type="alternate" />
			</PageSection>

			<PageSection background="grey">
				<ProductListing />
			</PageSection>
		</Page>
	);
};

export default ProductsPage;
