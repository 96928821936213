import {
	PRODUCT_CATEGORIES,
	PRODUCT_GROUPS,
	PRODUCT_ORDERING,
} from '@utils/constants';
import {
	SVGPicker,
	constructGroupSlug,
	constructProductSlug,
} from '@utils/helpers';
import { Link, StaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';

import * as styles from './Listing.module.css';

import Grid from '@components/Layout/Grid/Grid';
import Accordion from '@components/Molecule/Accordion/Accordion';
import Button from '@components/Molecule/Button/Button';
import ChevronDown from '@components/SVG/ChevronDown';
import ChevronUp from '@components/SVG/ChevronUp';
import Copy from '@components/Typography/Copy';

const ProductListing = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					category: allPrismicProductCategory {
						edges {
							node {
								uid
								data {
									title
									codename
									image {
										gatsbyImageData
										alt
									}
									content {
										html
									}
									seo_title
									seo_description
									seo_image {
										gatsbyImageData
										alt
									}
								}
							}
						}
					}

					group: allPrismicProductGroup {
						edges {
							node {
								uid
								data {
									title
									image {
										gatsbyImageData
									}
									content {
										html
									}
									categorisation {
										document {
											... on PrismicProductCategory {
												uid
												data {
													title
												}
												type
											}
										}
									}
								}
							}
						}
					}

					product: allPrismicProduct {
						edges {
							node {
								uid
								data {
									title
									summary {
										text
									}
									content {
										html
									}
									downloads {
										download_title
										download_file {
											url
											size
										}
									}
									key_specifications {
										html
									}
									product_image {
										gatsbyImageData
										alt
									}
									cadenas_configurator_url
									categorisation {
										document {
											... on PrismicProductGroup {
												uid
												data {
													title
												}
												type
											}
											... on PrismicProductCategory {
												uid
												data {
													title
												}
												type
											}
										}
									}
									seo_title
									seo_image {
										alt
										gatsbyImageData
									}
									seo_description
								}
							}
						}
					}
				}
			`}
			render={({
				category,
				group,
				product,
			}: {
				category: any;
				group: any;
				product: any;
			}): JSX.Element => {
				const [expandAll, setExpandAll] = useState(false);

				return (
					<>
						<Button
							theme="ghost"
							align="center"
							onClick={() => setExpandAll(!expandAll)}>
							{expandAll ? 'Retract' : 'Expand'} all Categories
							{expandAll ? <ChevronUp /> : <ChevronDown />}
						</Button>
						<Grid>
							<Category
								category={category}
								group={group}
								product={product}
								expandAll={expandAll}
								setExpandAll={setExpandAll}
							/>
						</Grid>
					</>
				);
			}}
		/>
	);
};

const Category = ({
	category,
	group,
	product,
	expandAll,
	setExpandAll,
}: {
	category: any;
	group: any;
	product: any;
	expandAll: boolean;
	setExpandAll: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}) => {
	category.edges.sort(
		(a, b) =>
			PRODUCT_CATEGORIES.indexOf(a.node.data.codename) -
			PRODUCT_CATEGORIES.indexOf(b.node.data.codename)
	);

	return category.edges?.map(({ node }, key) => {
		const { uid } = node;
		const { title } = node.data;

		const groups = group.edges.filter(
			({ node }) => node.data.categorisation.document?.uid === uid
		);

		groups.sort(
			(a, b) =>
				PRODUCT_GROUPS.indexOf(a.node.uid) -
				PRODUCT_GROUPS.indexOf(b.node.uid)
		);

		const productsWithoutGroups = product.edges.filter(
			({ node }) => node.data.categorisation.document?.uid === uid
		);

		return (
			<Accordion
				svg={SVGPicker(uid)}
				label={title}
				link={{
					url: uid,
					label: 'View category page',
				}}
				key={key}
				expandAll={expandAll}
				setExpandAll={setExpandAll}
				id={key}>
				<div className={styles.grid}>
					<Group
						category={category}
						groups={groups}
						group={group}
						product={product}
					/>

					{productsWithoutGroups?.length > 0 && (
						<div className={styles.group}>
							<Copy as="ul" listStyle="none" spacing="lg">
								{productsWithoutGroups.map(({ node }, key) => {
									const { title } = node.data;

									return (
										<li key={key}>
											<Link
												to={constructProductSlug(
													node,
													group
												)}
												key={key}>
												{title}
											</Link>
										</li>
									);
								})}
							</Copy>
						</div>
					)}
				</div>
			</Accordion>
		);
	});
};

const Group = ({
	category,
	groups,
	group,
	product,
}: {
	category: any;
	groups: any;
	group: any;
	product: any;
}) => {
	return groups?.length > 0
		? groups.map(
				(
					{
						node,
					}: {
						node: any;
					},
					key: number
				) => {
					const { uid } = node;
					const { title } = node.data;

					const products = product.edges.filter(
						({ node }) =>
							node.data.categorisation.document?.uid === uid
					);

					products.sort(
						(a, b) =>
							PRODUCT_ORDERING.indexOf(a.node.uid) -
							PRODUCT_ORDERING.indexOf(b.node.uid)
					);

					return (
						<div className={styles.group} key={key}>
							<h4>
								<Link to={constructGroupSlug(node, category)}>
									{title}
								</Link>
							</h4>

							<Product group={group} products={products} />
						</div>
					);
				}
		  )
		: null;
};

const Product = ({ group, products }: { group: any; products: any }) => {
	return (
		<Copy as="ul" listStyle="none" spacing="sm">
			{products.map(
				(
					{
						node,
					}: {
						node: any;
					},
					key: number
				) => {
					const { title } = node.data;

					return (
						<li key={key}>
							<Link
								to={constructProductSlug(node, group)}
								key={key}>
								{title}
							</Link>
						</li>
					);
				}
			)}
		</Copy>
	);
};

export default ProductListing;
